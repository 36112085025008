import React from 'react';
import { CssBaseline, ThemeProvider, Box, Typography } from '@material-ui/core';
import App, { AppProps } from 'next/app';
import Router, { useRouter } from 'next/router';
import { SnackbarProvider } from 'notistack';
import { initGA, logPageView } from 'lib/google/analytics';
import theme from 'theme';
import Header from 'molecules/Header';
import { DefaultSeo } from 'next-seo';
import Footer from 'atoms/Footer';
import { CommerceProvider } from '@bigcommerce/storefront-data-hooks';
import { ParallaxProvider } from 'react-scroll-parallax';

export default function MyApp(props: AppProps) {
    const { Component, pageProps } = props;
    const router = useRouter();
    const { locale = 'en-US' } = router;

    React.useEffect(() => {
        // Remove the server-side injected CSS
        const jssStyles = document.querySelector('#jss-server-side');
        if (jssStyles && jssStyles.parentElement) {
            jssStyles.parentElement.removeChild(jssStyles);
        }

        if (!window.GA_ANALYTICS && process.env.NODE_ENV === 'production') {
            initGA();
            window.GA_ANALYTICS = true;
            logPageView(window.location.pathname);
            Router.events.on('routeChangeComplete', (url) => {
                logPageView(url);
                window.scrollTo(0, 0);
            });
        }
    }, []);
    const renderHeader = !['/', '/signup'].includes(router.pathname);

    return (
        <>
            <ThemeProvider theme={theme}>
                <SnackbarProvider>
                    <CssBaseline />
                    <DefaultSeo
                        title={'Smelly & Icky'}
                        description={'A collaboration-centric art production duo'}
                        openGraph={{
                            images: [
                                {
                                    url:
                                        'https://www.smellyxicky.co/static/img/smellyxicky-web.png',
                                },
                            ],
                        }}
                    />
                    <ParallaxProvider>
                        <CommerceProvider locale={locale}>
                            {renderHeader && <Header />}
                            <Box
                                marginTop={renderHeader ? '80px' : 0}
                                width={'100%'}
                                minHeight={'calc(100vh - 80px)'}
                                display={'flex'}
                                flexDirection={'column'}
                                justifyContent={'space-between'}
                            >
                                <Component {...pageProps} />
                                {renderHeader && <Footer />}
                            </Box>
                        </CommerceProvider>
                    </ParallaxProvider>
                </SnackbarProvider>
            </ThemeProvider>
        </>
    );
}
