export const ROUTES = {
    HOME: {
        path: '/',
        name: 'Home',
    },
    ABOUT: {
        path: '/about',
        name: 'About',
    },
    MEDIA: {
        path: '/media',
        name: 'Media',
    },
    SHOP: {
        path: '/shop',
        name: 'Shop',
    },
    CART: {
        path: '/cart',
        name: 'Cart',
    },
    CHECKOUT: {
        path: '/checkout',
        name: 'Checkout',
    },
    SIGNUP: {
        path: '/signup',
        name: 'Signup',
    },
};

export const NAV_LINKS = [ROUTES.SHOP, ROUTES.MEDIA, ROUTES.ABOUT];
