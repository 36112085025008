import React from 'react';
import clsx from 'clsx';
import useStyles from './styles';
import { Box, Typography } from '@material-ui/core';

const Footer = (props: FooterPropTypes) => {
    const classes = useStyles();

    return (
        <footer>
            <Box width={'100%'} textAlign={'center'} paddingTop={3} paddingBottom={3}>
                <Typography variant={'caption'}>
                    Copyright &copy; Smelly & Icky 2020.
                </Typography>
            </Box>
        </footer>
    );
};

export type FooterPropTypes = {};

export default Footer;
