import { Theme, makeStyles } from '@material-ui/core';

const styles: any = (theme: Theme) => ({
    appBar: {
        backgroundColor: theme.palette.common.white,
    },
    navLink: {
        margin: theme.spacing(2),
        padding: theme.spacing(1),
        borderRadius: '3px',
        '&:hover': {
            backgroundColor: theme.palette.custom.smelly1,
            color: theme.palette.common.white,
        },
    }
});

export default makeStyles(styles);
