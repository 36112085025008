import React from 'react';
import clsx from 'clsx';
import useStyles from './styles';
import { AppBar, Toolbar, IconButton, Typography, Box } from '@material-ui/core';
import { useRouter } from 'next/router';
import { CartOutline } from 'mdi-material-ui';
import useCart from '@bigcommerce/storefront-data-hooks/cart/use-cart';
import BrandLogo from 'atoms/BrandLogo';
import BaseLink from 'atoms/BaseLink';
import { NAV_LINKS, ROUTES } from 'config/Nav';

const countItem = (count: number, item: any) => count + item.quantity;
const countItems = (count: number, items: any[]) => items.reduce(countItem, count);

const Header = (props: HeaderPropTypes) => {
    const classes = useStyles();
    const router = useRouter();
    const { data } = useCart();

    const itemCount = Object.values(data?.line_items ?? {}).reduce(countItems, 0);

    return (
        <AppBar variant={'outlined'} elevation={0} style={{ backgroundColor: 'white' }}>
            <Toolbar
                style={{
                    height: '80px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}
            >
                <Box height={'100%'}>
                    <BrandLogo headerMode />
                </Box>
                <Box
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                >
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        marginRight={2}
                    >
                        {NAV_LINKS.map((link) => (
                            <BaseLink key={link.path} href={link.path} className={classes.navLink}>
                                <Typography variant={'body2'}>{link.name}</Typography>
                            </BaseLink>
                        ))}
                    </Box>
                    <IconButton
                        color={'secondary'}
                        onClick={() => router.push(ROUTES.CART.path)}
                        disabled={itemCount === 0}
                    >
                        <CartOutline />
                        <Typography>{itemCount}</Typography>
                    </IconButton>
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export type HeaderPropTypes = {};

export default Header;
