import { createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme({
    typography: {
        h5: {
            fontFamily: 'variex, sans-serif',
        },
        h6: {
            fontFamily: 'variex, sans-serif',
        },
        body1: {
            fontFamily: `'Oxygen', sans-serif`,
        },
        body2: {
            fontFamily: 'variex, sans-serif',
        },
        button: {
            fontFamily: 'variex, sans-serif',
            color: '#FFFFFF',
        },
        allVariants: {
            textDecoration: 'none',
        }
    },
    palette: {
        primary: {
            main: '#6900D9',
        },
        secondary: {
            main: '#FFB000',
        },
        background: {
            default: '#f9f9fa',
            paper: '#eeedf0',
        },
        success: {
            main: '#00F27D',
        },
        error: {
            main: '#FF006F',
        },
        common: {
            black: '',
            white: '#FFFFFF',
        },
        custom: {
            smelly1: '#6900D9',
            smelly2: '#00F27D',
            icky1: '#FF006F',
            icky2: '#00E6BB',
            yellow: '#FFB000',
            grey: {
                light: '#f9f9fa',
                mid: '#eeedf0',
                dark: '#d5d1d9',
            },
            footer: {
                dark: '#1B2024',
                light: '#23282C',
            },
        },
    },
});

export default theme;
