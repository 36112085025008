import React from 'react';
import clsx from 'clsx';
import Image from 'next/image';
import useStyles from './styles';
import BaseLink from 'atoms/BaseLink';
import { ROUTES } from 'config/Nav';
import { LOGO } from 'config/assets';
import { Box } from '@material-ui/core';

const BrandLogo = ({ headerMode }: BrandLogoPropTypes) => {
    const classes = useStyles();

    const sizes: any = headerMode ? { height: '100%' } : { width: '100%' };

    return headerMode ? (
        <BaseLink href={ROUTES.HOME.path}>
            <Image height={'75px'} width={'213px'} src={LOGO.DEFAULT} />
        </BaseLink>
    ) : (
        <Box width={'75vw'}>
            <img width={'100%'} src={LOGO.DEFAULT} />
        </Box>
    );
};

export type BrandLogoPropTypes = {
    headerMode?: boolean;
};

export default BrandLogo;
